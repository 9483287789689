import React from 'react';
import PropTypes from 'prop-types';
import { LaptopImg } from '../img';

const CaseStudy = ({
  header, about, challenge, solution,
}) => (
  <div>
    <h1>{header}</h1>
    <p>{about}</p>
    <LaptopImg />
    <h2>Challenge</h2>
    <p>{challenge}</p>
    <h2>Solution</h2>
    <p>{solution}</p>
    <h2>Applied Technologies</h2>
  </div>
);

CaseStudy.propTypes = {
  header: PropTypes.string.isRequired,
  about: PropTypes.string.isRequired,
  challenge: PropTypes.string.isRequired,
  solution: PropTypes.string.isRequired,
};

export default CaseStudy;
