import React from 'react';
import Layout from '../../components/home/Layout';
import CaseStudy from '../../components/projects/CaseStudy';

const MachineShopExample = () => (
  <Layout>
    <CaseStudy
      header="headerText"
      about="aboutText"
      challenge="challengeText"
      solution="solutionText"
    />
  </Layout>
);

export default MachineShopExample;
